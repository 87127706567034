<template>
    <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div>
    <div v-else>
      <loading :loading="loading" />
      <v-card>
      <v-tabs v-model="tab" background-color="primary" dark>
        <v-tab v-for="(item, index) in items" :key="index">
          {{ item.name }}
        </v-tab>
        <v-spacer/>
      
      </v-tabs>

      <v-tabs-items v-if="tab==0">
        <v-row class="ma-0 pt-1">
          <v-col cols="12" class="pa-0" align="center">
            <span class="color-red-pk fn-30"><b> PACKHAI </b></span><span class="color-blue-pk fn-30"> SUBSCRIPTION </span>
          </v-col>
        </v-row>

        <v-row class="ma-0">
          <v-col cols="1" class="pa-0"/>
          <v-col cols="5" class="pa-0">
            <div class="pk-15-blue"/>
          </v-col>
          <v-col cols="5" class="pa-0" align="end">
            <div class="pk-15-red"/>
          </v-col>
          <v-col cols="1" class="pa-0"/>
        </v-row>

        <v-row class="ma-0 pt-3">
          <v-col cols="12" class="pa-0" align="center">
            <span class="color-blue-pk fn-20"><b> แพ็คเกจรายเดือน </b></span>
          </v-col>
        </v-row>


        <v-row class="ma-0">
          <v-col cols="12" md="4" lg="4" class="py-5 px-7" align="center">
            <v-card elevation="0" max-width="280">
              <div class="pk-15-blue-2"/>
            </v-card>
            <v-card elevation="0" class="package-subscription-card" max-width="332">
              <v-card-text class="px-7">
                <v-row class="ma-0 pt-1">
                  <v-col cols="12" class="pa-0" align="center">
                    <span class="color-red-pk fn-20"><b> Basic </b></span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-row class="ma-0">
                <v-col cols="12" class="pa-2 bg-color-blue-pk" align="center">
                  <span  > เหมาะสำหรับ </span>
                  <br/>
                  <span ><b> แม่ค้ามือใหม่ </b></span>
                </v-col>
              </v-row>
              <v-card-text class="px-7">
                <v-row class="ma-0 pt-1">
                  <v-col cols="12" class="pt-2" align="left">
                    <v-icon class="color-green  pr-2">mdi-check</v-icon>
                    <span><b> 1,000 ออเดอร์/เดือน </b></span>
                  </v-col>
                 
                  <v-col cols="12" class="pt-2" align="left">
                    <v-icon class="color-green  pr-2">mdi-check</v-icon>
                    <span><b> ไม่จำกัดจำนวนสินค้า </b></span>
                  </v-col>
                  <v-col cols="12" class="pt-2" align="left">
                    <v-icon class="color-green  pr-2">mdi-check</v-icon>
                    <span><b> 2 คลังสินค้า </b></span>
                  </v-col>
                  <v-col cols="12" class="pt-2" align="left">
                    <v-icon class="color-green  pr-2">mdi-check</v-icon>
                    <span><b> 5 แอดมิน </b></span>
                  </v-col>
                  <v-col cols="12" class="pt-2" align="left">
                    <v-icon class="color-green  pr-2">mdi-check</v-icon>
                    <span><b> Marketplace ช่องทางละ 2 ร้านค้า </b></span>
                  </v-col>
                </v-row>
              </v-card-text>
           
              <v-row class="ma-0 pa-3">
                <v-col cols="12" align="center">
                  <v-btn :elevation="1" class="pa-2 px-8 color-white" color="#1D3E74" dark @click="OPPPayment('Basic',399)">
                    <v-icon size="18">fa-solid fa-baht-sign</v-icon>
                    <span class="fn-20"><b>399</b></span><span class="fn-12 pt-1"> /เดือน</span>
                  </v-btn>
                </v-col>
                <v-col cols="12" align="center" class="fn-11">
                  <span class="color-red-pk pt-1"><b>แพ็คเกจรายเดือน (Exc. VAT 7%)</b></span>
                </v-col>
              </v-row>
            </v-card>
            <v-card elevation="0" max-width="280">
              <div class="pk-15-blue-3"/>
            </v-card>
          </v-col>

          <v-col cols="12"  md="4" lg="4" class="py-5 px-7" align="center">
            <v-card elevation="0" max-width="280">
              <div class="pk-15-red-2"/>
            </v-card>
            <v-card elevation="0" class="package-subscription-card" max-width="332">
              <v-card-text class="px-7">
                <v-row class="ma-0 pt-1">
                  <v-col cols="12" class="pa-0" align="center">
                    <span class="color-red-pk fn-20"><b> Pro </b></span>
                  </v-col>
                 
                </v-row>
              </v-card-text>
              <v-row class="ma-0">
                <v-col cols="12" class="pa-2 bg-color-red-pk" align="center">
                  <span  > เหมาะสำหรับ </span>
                  <br/>
                  <span  ><b> แม่ค้ามือเก๋า </b></span>
                </v-col>
              </v-row>
              <v-card-text class="px-7">
                <v-row class="ma-0 pt-1">
                  <v-col cols="12" class="pt-2" align="left">
                    <v-icon class="color-green  pr-2">mdi-check</v-icon>
                    <span><b> Unlimited ออเดอร์ </b></span>
                  </v-col>
                 
                  <v-col cols="12" class="pt-2" align="left">
                    <v-icon class="color-green  pr-2">mdi-check</v-icon>
                    <span><b> ไม่จำกัดจำนวนสินค้า </b></span>
                  </v-col>
                  <v-col cols="12" class="pt-2" align="left">
                    <v-icon class="color-green  pr-2">mdi-check</v-icon>
                    <span><b> 5 คลังสินค้า </b></span>
                  </v-col>
                  <v-col cols="12" class="pt-2" align="left">
                    <v-icon class="color-green  pr-2">mdi-check</v-icon>
                    <span><b> ไม่จำกัดแอดมิน </b></span>
                  </v-col>
                  <v-col cols="12" class="pt-2" align="left">
                    <v-icon class="color-green  pr-2">mdi-check</v-icon>
                    <span><b> Marketplace เชื่อมต่อไม่จำกัด </b></span>
                  </v-col>
                </v-row>
              </v-card-text>
            
              <v-row class="ma-0 pa-3">
                <v-col cols="12" align="center">
                  <v-btn :elevation="1" class="pa-2 px-6 color-white" color="#E32222" dark @click="OPPPayment('Pro',899)">
                    <v-icon size="18">fa-solid fa-baht-sign</v-icon>
                    <span class="fn-20"><b>899</b></span><span class="fn-12 pt-1"> /เดือน</span>
                  </v-btn>
                </v-col>
                <v-col cols="12" align="center" class="fn-11">
                  <span class="color-blue-pk pt-1"><b>แพ็คเกจรายเดือน (Exc. VAT 7%)</b></span>
                </v-col>
              </v-row>
            </v-card>
            <v-card elevation="0" max-width="280">
              <div class="pk-15-red-3"/>
            </v-card>
          </v-col>
 
        </v-row>
      </v-tabs-items>

      <v-tabs-items v-if="tab==1">
        <v-card elevation="1">
          <v-card-text>
            <!-- PC hidden-xs-only -->


           
            <v-data-table
             
              :headers="header"
              :items="dataset"
              :items-per-page="9999999999"
              hide-default-footer
              class="packhai-border-table"
            >
              <template v-slot:item.createdDatetime="{ item }">
                {{ formatDatetime(item.createdDatetime) }}
              </template>
              <template v-slot:item.price="{ item }">
                <v-row class="ma-0">
                  <v-col cols="12" class="px-0" align="end">
                    {{ formatMoneyIsDecimal(item.price) }}
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.vat="{ item }">
                <v-row class="ma-0">
                  <v-col cols="12" class="px-0" align="end">
                    {{ formatMoneyIsDecimal(item.vat) }}
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.service="{ item }">
                <v-row class="ma-0">
                  <v-col cols="12" class="px-0" align="end">
                    {{ formatMoneyIsDecimal(item.service) }}
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.totalPrice="{ item }">
                <v-row class="ma-0">
                  <v-col cols="12" class="px-0" align="end">
                    {{ formatMoneyIsDecimal(item.totalPrice) }}
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.dateTimeCredit="{ item }">
                {{  item.dateTimeCredit  }}
                <span v-if="item.dateTimeCreditUnit=='Month'">
                   เดือน
                </span>
                <span v-else-if="item.dateTimeCreditUnit=='Day'">
                   วัน
                </span>
                <span v-else-if="item.dateTimeCreditUnit=='Year'">
                  ปี
                </span>

              </template>
              <template v-slot:item.orderCredit="{ item }">
                <span v-if="item.orderCredit==null">
                  Unlimit
                </span>
                <span>
                  {{ formatMoney(item.orderCredit) }}
                </span>
               
              </template>
              <template v-slot:item.channelPayment="{ item }">
                <span v-if="item.channelPayment==0"> QR PromptPay </span>
                <span v-else-if="item.channelPayment==1"> บัตรเครดิต/เดบิต </span>
                <span v-else-if="item.channelPayment==2"> โอนเงินผ่านบัญชีธนาคาร </span>
              </template>
              <template v-slot:item.isPayment="{ item }">
                <span v-if="item.isPayment" class="color-green"><v-icon color="success">mdi-checkbox-marked-circle-outline</v-icon> สำเร็จ </span>
                <span v-else class="color-red"><v-icon color="red">mdi-close-circle-outline</v-icon> ไม่สำเร็จ </span>
              </template>
              <template v-slot:item.completeTime="{ item }">
                {{ formatDatetime(item.completeTime) }}
              </template>
            </v-data-table>

          

            <!-- เปลี่ยนหน้า -->
            <v-row v-if="pageLength>1 && dataset.length>0" class="text-center pa-2 ma-0" @click="changePage()">
              <v-col cols="12" class="pa-0">
                <v-pagination
                  v-model="page"
                  :length="pageLength"
                  :total-visible="pageTotalVisible"
                  color="info"
                ></v-pagination>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-tabs-items>
    </v-card>

    <v-dialog v-model="dspayment" max-width="600" persistent>
      <v-stepper v-model="step" alt-labels>
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            ช่องทาง
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">
            ดำเนินการ
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3">
            สำเร็จ
          </v-stepper-step>
          <v-btn icon @click="CloseDialogPayment()"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-row class="ma-0 pb-3">
              <v-col cols="12" class="pa-0" align="center">
                <span class="pl-2 fn-18"> เลือกช่องทางการชำระเงิน </span><br>
                <img class="pl-3" style="height:30px"  src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/securepayment.png"/>
              </v-col>
            </v-row>
            <v-row class="mt-5 pb-3">
              <v-col cols="12">
                <v-radio-group v-model="channelpayment.channel" >
                  <v-radio v-for="(i, index) in channelpayment.channelList" color="red" :key="index"   :value="i">
                    <template v-slot:label>
                      <span class="pl-3" style="font-size: 14px;">  {{ i.name }}</span>
                      <div  v-if="i.id==0">
                        <img class="pl-3" style="height:20px"  src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/promtpay.png"/>
                      </div>
                      <div  v-else>
                        <img class="pl-3"  style="height:20px"  src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/visa.png"/>
                      <img class="pl-3" style="height:20px"  src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/mastercard.webp"/>
                      </div>
                  

                    </template>  
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="end">
                <v-btn :elevation="1" color="primary" @click="NextStep2()">
                  <span> ถัดไป </span>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="2">
            <div v-if="channelpayment.qrImage==null">
              <v-row class="ma-0 pb-3" v-if="!channelpayment.loading">
                <v-col cols="12" class="pa-0" align="center">
                  <span class="pl-2 fn-18"> สรุปยอดชำระ </span>
                </v-col>
              </v-row>
              <v-row class="ma-0 pb-4" v-if="!channelpayment.loading">
                <v-col cols="6" class="pa-1">
                  ยอดที่ต้องชำระ
                </v-col>
                <v-col cols="6" class="pa-1" align="end">
                  {{ formatMoneyIsDecimal(channelpayment.price) }} บาท
                </v-col>
                <v-col cols="6" class="pa-1">
                  ภาษีมูลค่าเพิ่ม 7%
                </v-col>
                <v-col cols="6" class="pa-1" align="end">
                  {{ formatMoneyIsDecimal(channelpayment.vat) }} บาท
                </v-col>
                <v-col cols="6" class="pa-1" v-if="channelpayment.channel.id==1">
                  ค่าธรรมเนียม 2.8%
                </v-col>
                <v-col cols="6" class="pa-1" align="end" v-if="channelpayment.channel.id==1">
                  {{ formatMoneyIsDecimal(channelpayment.service) }} บาท
                </v-col>
                <v-col cols="6" class="pa-1">
                  ยอดชำระทั้งหมด
                </v-col>
                <v-col cols="6" class="pa-1" align="end">
                  <b>{{ formatMoneyIsDecimal(channelpayment.totalprice) }}</b> บาท
                </v-col>
              </v-row>
              <v-row class="ma-0 pb-4" v-else>
                <v-col cols="12" class="pa-1" align="center">
                  <v-progress-circular
                    :size="117"
                    :width="7"
                    color="red"
                    indeterminate
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <v-row class="ma-0 pb-3" v-if="!channelpayment.loading">
                <v-col cols="12" class="pa-0" align="center">
                  <img :src="`data:image/png;base64,${this.channelpayment.qrImage}`" width="200"><br>
                 <span style="font-size: 20px;" >ยอด <b >   {{ formatMoneyIsDecimal(channelpayment.totalprice) }}</b> บาท </span>
                </v-col>
              </v-row>
              <v-row class="ma-0 pb-4" v-else>
                <v-col cols="12" class="pa-1" align="center">
                  <v-progress-circular
                    :size="117"
                    :width="7"
                    color="red"
                    indeterminate
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </div>

            <v-row>
              <v-col cols="5">
                <v-btn :elevation="1" color="primary" :disabled="channelpayment.loading" outlined @click="ClearChannelPayment()">
                  <v-icon>mdi-chevron-left</v-icon>
                  <span> กลับ </span>
                </v-btn>
              </v-col>
              <v-col cols="2" align="center">
                <span v-if="channelpayment.second!=null">{{channelpayment.minute +":"+ CheckSecond(channelpayment.second)}}</span>
              </v-col>

              <v-col cols="5" align="end" v-if="channelpayment.tradeNo==null">
                <v-btn :elevation="1" color="primary" :disabled="channelpayment.loading" @click="Payment()">
                  <span> ชำระเงิน </span>
                </v-btn>
              </v-col>

              <v-col cols="5" align="end" v-else>
                <v-btn :elevation="1" color="primary" :loading="this.channelpayment.loadingCh" @click="PaymentCheckStatus()">
                  <span> ตรวจสอบสถานะ </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-row class="ma-0 pb-3">
              <v-col cols="12" align="center">
                <div v-if="channelpayment.issuccess">
                  <v-icon  size="130" color="success">mdi-checkbox-marked-circle-outline</v-icon><br>
                  <span   style="font-size: 20px;">ชำระเงินสำเร็จ</span>
                </div>
                <div v-else>
                  <v-icon  size="130" color="red">mdi-close-circle-outline</v-icon><br>
                  <span  style="font-size: 20px;">ชำระเงินไม่สำเร็จ</span>
                </div>
              
               
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" align="end">
                <v-btn :elevation="1" color="primary" @click="ColseDialogPayment()">
                  <span> ปิดหน้าต่าง <span v-if="iterations!=null">({{iterations}})</span> </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>
    </div>
</template>

<script>
    import Loading from '@/website/components/Loading'
    import axios from 'axios'
    import Swal from 'sweetalert2'
    import { orderService_dotnet, shopService_dotnet } from '@/website/global'
    import { formatMoneyIsDecimal } from '@/website/global_function'
    import { token_api  } from '@/website/backend/token'

    // import VueBarcode from 'vue-barcode'
    export default {
        components: {
            Loading,
            // 'barcode': VueBarcode
        },

        data: () => ({
            header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
            page_loading: true,
            loading: false,
            dspayment:false,
            step: 1,
            iterations: null,
            interval: null,
            channelpayment: {
              loading: false,
              loadingCh: false,
              size: null,
              price: 0,
              vat: 0,
              service: 0,
              totalprice: 0,
              issuccess: false,
              minute: null,
              second: null,
              interval: null,
              qrImage: null,
              redirectUrl: null,
              tradeNo: null,
              channel: { id: 0, name: "QR PromptPay" },
              channelList: [
                { id: 0, name: "QR PromptPay" },
                { id: 1, name: "บัตรเครดิต/เดบิต" },
                // { id: 2, name: "โอนเงินผ่านบัญชีธนาคาร" }
              ],
            },
            items: [
              { id: 0, name: 'ซื้อแพ็คเกจ' },
              { id: 1, name: 'ประวัติการซื้อแพ็คเกจ' },
            ],
            tab: null,
            dataset: [],
            header: [
              { align: 'center', text: 'วันที่ทำรายการ', value: 'createdDatetime', sortable: true, width: '150px' },
              { align: 'center', text: 'แพ็คเกจ', value: 'packageName', sortable: false, width: '80px' },
              { align: 'center', text: 'ราคา', value: 'price', sortable: false, width: '80px' },
              { align: 'center', text: 'ภาษี 7%', value: 'vat', sortable: false, width: '80px' },
              { align: 'center', text: 'ค่าธรรมเนียม 2.8%', value: 'service', sortable: false, width: '80px' },
              { align: 'center', text: 'รวมทั้งหมด', value: 'totalPrice', sortable: false, width: '80px' },
              { align: 'center', text: 'เพิ่มวัน', value: 'dateTimeCredit', sortable: false, width: '80px' },
              { align: 'center', text: 'เพิ่มออเดอร์', value: 'orderCredit', sortable: false, width: '80px' },
              { align: 'center', text: 'ช่องทาง', value: 'channelPayment', sortable: false, width: '80px' },
              { align: 'center', text: 'ชำระเงิน', value: 'isPayment', sortable: false, width: '80px' },
              { align: 'center', text: 'วันที่ชำระ', value: 'completeTime', sortable: false, width: '80px' },
            ],

            // paginations
            page: 1,
            pageTemp: 1,
            pageLength: 1,
            pageTotalVisible: 9,
            limits: 50,
            offsets: 0,
             
        }),

        computed: {
          
        },

        watch: {
           
        },

        async created() {

           //เก็บภาษามาไว้ใน use_language
           await this.GetBranchSubscriptionHistory(this.limits,this.offsets,this.page);
    
           this.page_loading=false;
        },

        methods: {
          async changePage() {
            if (this.pageLength!=1 && this.pageTemp!=this.page){
              var offsets = (this.page*this.limits)-this.limits
              this.GetBranchSubscriptionHistory(this.limits,offsets,this.page)
              this.pageTemp = this.page
            }
          },
          async GetBranchSubscriptionHistory (limits,offsets,page){
            this.loading = true
            let response = await axios.post(shopService_dotnet+'Subscription/get-subscriptionhistory', {
              "shopId": localStorage.getItem("shop_id"),
              "skip": offsets,
              "take": limits,
            },{headers: this.header_token})
            if (response.status==200){
              this.loading = false
              this.page = page
              this.offsets = offsets
              this.dataset = response.data.resultData
              this.pageLength =  (Math.ceil(response.data.resultCount/limits))
              if (this.pageLength<=0){this.pageLength=1}
            }
          },
          ClearChannelPayment(){
            this.channelpayment.issuccess = false
            this.channelpayment.tradeNo = null
            this.channelpayment.qrImage = null
            this.step = 1
          },
          OPPPayment(size,price){
            
            this.ClearChannelPayment()
            this.channelpayment.size = size
            this.channelpayment.price = price
            this.channelpayment.channel = this.channelpayment.channelList[0]
            this.dspayment = true
           
          },
          async Payment(){
        // clearInterval(this.channelpayment.interval);
            this.channelpayment.loading = true
            var size = this.channelpayment.size
            var order = 0
            if (size=="Basic"){ order=1000 }
            else if (size=="Pro"){ order=null } 

            var data = {  
              shopID:  localStorage.getItem("shop_id"),
              packageName: size,
              Price: this.channelpayment.price,
              Vat: this.channelpayment.vat,
              Service: this.channelpayment.service,
              TotalPrice: this.channelpayment.totalprice,
              dateTimeCredit: 1,
              dateTimeCreditUnit:"Month",
              orderCredit: order,
              ChannelPayment: this.channelpayment.channel.id,
            }

          
            let response = await axios.post(shopService_dotnet+'Subscription/payment-create',data, {headers: this.header_token})
            if (response.status == 200){
              if (response.data.status == 200){
                this.channelpayment.loading = false
                this.channelpayment.tradeNo = response.data.tradeNo
                if (data.ChannelPayment==0) {
                  this.channelpayment.qrImage = response.data.qrImage
                } else if (data.ChannelPayment==1) {
                  this.channelpayment.redirectUrl = response.data.redirectUrl
                  window.open(this.channelpayment.redirectUrl);
                }
                // this.qrstart()
              }
            }
           
          },
          async PaymentCheckStatus() {
            this.channelpayment.loadingCh = true
            let response = await axios.post(shopService_dotnet+'Subscription/payment-status', {
              "tradeNo": this.channelpayment.tradeNo
            }, {headers: this.header_token})
            if (response.status == 200){
              this.channelpayment.loadingCh = false
              this.channelpayment.issuccess = true
              if (response.data){
                this.step = 3
              }
            }
          },
          CloseDialogPayment(){
            this.dspayment = false
            if (this.step==3){
              if (this.$router.currentRoute.path == "/package"){ this.$router.push('/subscription/package') }
              else { window.location.reload(); }
            }
            this.step = 1
          },
          NextStep2(){
            this.channelpayment.loading = false
            this.channelpayment.qrImage = null
            this.step = 2

            var price = this.channelpayment.price
            var vat = price * 0.07
            var service = 0
            if (this.channelpayment.channel.id==1){
              service = (price + vat) * 0.028
              this.channelpayment.totalprice = (price + vat) + service
            } else {
              this.channelpayment.totalprice = price + vat
            }
            this.channelpayment.service = service
            this.channelpayment.vat = vat
          },
          CheckSecond(second){
            var data = null
            data = second.toString()
            if (data.length==1) {
              data = "0"+data
            }
            return data
          },
           formatDatetime (datetime) {
            if (datetime==null || datetime==""){
              return null
            }
            return datetime.substring(8,10)+"/"+datetime.substring(5,7)+"/"+datetime.substring(0,4)+" "+datetime.substring(11,19)
          },
          formatMoney (num) {
            if (num!=null && num!='' && num!='null'){
              var money = ''
              try {
                num = num.split(".")
                if (num.length==1){
                  money = parseInt(num).toLocaleString()
                  return money
                }
                if (num.length==2){
                  money = parseInt(num[0]).toLocaleString()
                  money += "."+num[1]
                  return money
                }
              }
              catch(err){
                return num.toLocaleString()
              }
            }
            return num
          },
          formatMoneyIsDecimal 
        }
    }
</script>
<style scoped>
  .package-subscription-card{
    border: 1px solid #02475E !important;
    border-radius: 20px !important;
  }

</style>
 