<template>
    <div>
      <loading :loading="loading" />
        <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
          <v-card-title  style="height: 60px;">
            <h4>ขั้นตอนวิธีการใช้งานในระบบ Oasys</h4>
          </v-card-title>
        </v-card>
        <v-card  class="v-card-bottom-30">
          <v-card-text>
            <v-row>
              <v-col cols="1" ></v-col>
              <v-col cols="10" >
                <v-list>
                  <!-- รอ refector code ทำแบบไม่cleancode ไปก่อน หากrefector ควรใช้วิธี เรียก api ดึงหัวข้อมาแล้ว loop link YT ที่ละอันเพื่อไม่ให้ code อ่านแล้วรกเกินไป -->
                  <!-- การตั้งค่าทั่วไป -->
                <v-list-group prepend-icon="mdi-cog" style="border: 1px solid rgba(0, 0, 0, 0.2);" color="#000">
                  <template v-slot:activator>
                    <v-list-item-title><h3>การตั้งค่าทั่วไป</h3></v-list-item-title>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>ข้อมูลร้านค้า</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                  <v-btn class="play-button" outlined @click="play(1.1)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>ที่อยู่ใบกำกับภาษี</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                    <v-btn class="play-button" outlined @click="play(1.2)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>ที่อยู่จัดส่ง</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                    <v-btn class="play-button" outlined @click="play(1.3)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>บัญชีธนาคาร</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn class="play-button" outlined @click="play(1.4)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                <br>
                <!-- การตั้งค่าขนส่ง -->
                <v-list-group  prepend-icon="mdi-truck" style="border: 1px solid rgba(0, 0, 0, 0.2);" color="#000">
                  <template v-slot:activator>
                    <v-list-item-title><h3>การตั้งค่าขนส่ง</h3></v-list-item-title>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>วิธีการเพิ่มขนส่ง Kerry</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                    <v-btn class="play-button" outlined @click="play(2.1)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>วิธีการเพิ่มขนส่ง ไปรษณีย์EMS</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                  <v-btn class="play-button" outlined @click="play(2.2)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>วิธีการเพิ่มขนส่ง J&T Express</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                    <v-btn class="play-button" outlined @click="play(2.3)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>วิธีการเพิ่มขนส่ง DHL Express</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                    <v-btn class="play-button" outlined @click="play(2.4)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>วิธีการเพิ่มขนส่ง Shopee Express</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn class="play-button" outlined @click="play(2.5)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                <br>
                <!-- การเชื่อมต่อ MARKETPLACE -->
                <v-list-group prepend-icon="mdi-connection" style="border: 1px solid rgba(0, 0, 0, 0.2)" color="#000">
                  <template v-slot:activator>
                    <v-list-item-title><h3>การเชื่อมต่อ MARKETPLACE</h3></v-list-item-title>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>วิธีการเชื่อมต่อ Shopee</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn class="play-button" outlined @click="play(3.1)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>วิธีการเชื่อมต่อ Lazada</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn class="play-button" outlined @click="play(3.2)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>วิธีการเพิ่มขนส่ง TIKTOK</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn class="play-button" outlined @click="play(3.3)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                <br>
                <!-- การสร้างสินค้าจาก MARKETPLACE -->
                <v-list-group prepend-icon="mdi-store" style="border: 1px solid rgba(0, 0, 0, 0.2)" color="#000">
                  <template v-slot:activator>
                    <v-list-item-title><h3>การสร้างสินค้าจาก MARKETPLACE</h3></v-list-item-title>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>การสร้างสินค้าจาก MARKETPLACE</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn class="play-button" outlined @click="play(4.1)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>วิธีการตั้งค่าสินค้าจัดเซ็ท</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn class="play-button" outlined @click="play(4.2)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>วิธีการผูกสินค้า</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn class="play-button" outlined @click="play(4.3)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                <br>
                <!-- การใช้งานสต๊อกสินค้า -->
                <v-list-group prepend-icon="mdi-cart" style="border: 1px solid rgba(0, 0, 0, 0.2)" color="#000">
                  <template v-slot:activator>
                    <v-list-item-title><h3>การใช้งานสต๊อกสินค้า</h3></v-list-item-title>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>การนำเข้าสต๊อกสินค้า</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn class="play-button" outlined @click="play(5.1)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>การนำออกสต๊อกสินค้า</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn class="play-button" outlined @click="play(5.2)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>การเช็คสต๊อกสินค้า</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn class="play-button" outlined @click="play(5.3)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                <br>
                <!-- ออเดอร์ -->
                <v-list-group prepend-icon="mdi-package-variant-closed" style="border: 1px solid rgba(0, 0, 0, 0.2)" color="#000">
                  <template v-slot:activator>
                    <v-list-item-title><h3>ออเดอร์</h3></v-list-item-title>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>การสร้างออเดอร์ใหม่</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn class="play-button" outlined @click="play(6.1)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>การปริ้นใบปะหน้า</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn class="play-button" outlined @click="play(6.2)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>การดูสถานะของออเดอร์</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn class="play-button" outlined @click="play(6.3)">
                    <v-icon >mdi-play</v-icon>play
                  </v-btn>
                  </v-list-item-action>
                  </v-list-item>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
                </v-list>
              <!-- vexpasion panels หากว่าไม่ชอบ list -->
                <!-- <v-expansion-panels multiple>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <h3>การตั้งค่าทั่วไป</h3>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      asdasds
                      <v-btn icon class="float-right">
                      <v-icon color="#00aeef">mdi-play</v-icon> play
                    </v-btn>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels> -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

    </div>
</template>

<script>

    import { format_number, format_order_id, format_price,get_languages } from '@/website/global_function'
 
   export default {

        data: () => ({

        }),

        computed: {
        },

        async created() {

           //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));

        },

        methods: {
          format_number,
          format_order_id,
          format_price,
          get_languages,
        play(choice)
        {
          // การตั้งค่าทั่วไป
          if(choice === 1.1){window.open('https://youtu.be/dB-BdbApdgo?si=ThH4lHZcFl6nTADE','_blank')}
          if(choice === 1.2){window.open('https://youtu.be/TnnR-ArJIq8?si=__wN22Esxppfk-Ux','_blank')}
          if(choice === 1.3){window.open('https://youtu.be/40HJ4wt74gk?si=aX_F6L0FBfVXxsv2','_blank')}
          if(choice === 1.4){window.open('https://youtu.be/XAmAaXIpJeU?si=DgH6wB172D_bMcy7','_blank')}
          // // การตั้งค่าขนส่ง
          if(choice === 2.1){window.open('https://youtu.be/vyumWMCOKDw?si=CHlhimIt3z54SM_9','_blank')}
          if(choice === 2.2){window.open('https://youtu.be/hN2suqDY0iY?si=-zRJt_y04FVPPlaX','_blank')}
          if(choice === 2.3){window.open('https://youtu.be/US0kSlH6xGA?si=QZ9YosXkPQ9BtxqU','_blank')}
          if(choice === 2.4){window.open('https://youtu.be/Yk6_OzEd_oQ?si=oYPB2GZB_FIvz-Mg','_blank')}
          if(choice === 2.5){window.open('https://youtu.be/0lksbM11FMo?si=QknpvqZFitRpJW6t','_blank')}
          // การเชื่อมต่อ marketplace
          if(choice === 3.1){window.open('https://youtu.be/8z43BwSUglg?si=fHjZpfJBWUwLqgGb','_blank')}
          if(choice === 3.2){window.open('https://youtu.be/JIAQUN4TcZk?si=eV7o0rJnKi8QIaKy','_blank')}
          if(choice === 3.3){window.open('https://youtu.be/qULE1oAHbjc?si=WvUv38pzlNxHOUzB','_blank')}
          // การสร้างสินค้าจาก marketplace
          if(choice === 4.1){window.open('https://youtu.be/Utqpd6qXjqw?si=meD7e8k3KCIFFnzh','_blank')}
          if(choice === 4.2){window.open('https://youtu.be/qc4B_yI1kQE?si=VkCaZ3M6vSuWkml_','_blank')}
          if(choice === 4.3){window.open('https://youtu.be/8uOOLiX__5s?si=OD2gSYjwCKIJJPRa','_blank')}
          // การใช้งานสต๊อกสินค้า
          if(choice === 5.1){window.open('https://youtu.be/-kcp0aQXkAA?si=rihzMVlsP1PFKDuW','_blank')}
          if(choice === 5.2){window.open('https://youtu.be/sXpZ8tujgnI?si=ZxYVe2M6t4WLfTQM','_blank')}
          if(choice === 5.3){window.open('https://youtu.be/WHRW1qcOUq0?si=ERDoqEfMHjm9cxg9','_blank')}
           // ออเดอร์
          if(choice === 6.1){window.open('https://youtu.be/1x3P_b72YmE?si=CDEXOro5T26I2qbf','_blank')}
          if(choice === 6.2){window.open('https://youtu.be/3kPWgw4LxFs?si=G7zyxyKmEM_vNXxw','_blank')}
          if(choice === 6.3){window.open('https://youtu.be/8PDW0pRxBjo?si=390rfR-KJfvy2nP7','_blank')}
        },

        }
    }
</script>
<style scoped>
.play-button {
  background-color: #fff;
  color: rgb(10, 191, 251);
  border-color: lightblue;
}

.play-button:hover {
  background-color: #23baaa; 
  color: #fff;
  border-color: #23baaa;
}
</style>
