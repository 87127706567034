<template>
    <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div>
    <div v-else>
      <loading :loading="loading" />
        <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
          <v-card-title  style="height: 60px;">
            <h4>{{use_language.report}}</h4><v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">{{use_language.report_cod}}</span>
          </v-card-title>
        </v-card>
        <v-card  class="v-card-bottom-30">
          <v-card-text>
            <v-row class="overflow-x-auto mt-0" v-if="isSearching != null">
              <v-col class="pt-0 px-0" cols="12">
                <v-tabs v-model="tabSelect">
                  <v-tab @click="changeTab(1)">{{use_language.grouped_by_date_cod}}</v-tab>
                  <v-tab @click="changeTab(2)">{{use_language.group_by_delivery_date}}</v-tab>
                </v-tabs>
              </v-col>
              <v-col class="pt-0 px-0" cols="12" v-if="this.tab == 1">
                <v-row class="mt-0 px-0 mx-0 pb-1" style="background: #fff;">
                  <v-col cols="12" md="3" lg="3" >
                    <v-menu
                      ref="menu_search_tranferred_date_from"
                      v-model="menu_search_tranferred_date_from"
                      :close-on-content-click="false"
                      :return-value.sync="transferred_date_from"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="computed_search_tranferred_date_from"
                          @change="value => computed_search_tranferred_date_from = value"
                          autocomplete="off"
                          label="เริ่ม"
                          outlined
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          hide-details
                          v-on="on"
                        >
                          <template v-slot:label> <span>{{use_language.start}}</span> </template> 
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="transferred_date_from" @input="menu_search_tranferred_date_from = false, $refs.menu_search_tranferred_date_from.save(transferred_date_from)" no-title :allowed-dates="allowedDates" class="my-0">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" >
                    <v-menu
                      ref="menu_search_tranferred_date_to"
                      v-model="menu_search_tranferred_date_to"
                      :close-on-content-click="false"
                      :return-value.sync="transferred_date_to"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="computed_search_tranferred_date_to"
                          @change="value => computed_search_tranferred_date_to = value"
                          autocomplete="off"
                          label="สิ้นสุด"
                          outlined
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          hide-details
                          v-on="on"
                        >
                         <template v-slot:label> <span>{{use_language.end}}</span> </template> 
                        </v-text-field>
                      </template>
                      <v-date-picker v-model="transferred_date_to" @input="menu_search_tranferred_date_to = false, $refs.menu_search_tranferred_date_to.save(transferred_date_to)" no-title :allowed-dates="allowedDates" class="my-0">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" class="pb-0" align="center">
                    <v-btn @click="get_data_tab1()" color="primary" class="mr-3">{{use_language.search}}</v-btn>
                    <v-btn @click="SearchingClearData()" outlined color="primary" class="mr-3">{{use_language.reset}}</v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-0 mx-0 px-0">
                  <!-- ค้นหา 1 -->
                  <v-col cols="12">
                    <v-data-table :headers="headers1" :items="showdataTable1" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
                      <template v-slot:item.logoPicSmall="{ item }">
                        <div align="center">
                          <v-img style="margin:5px;"
                            :src="item.logoPicSmall"
                            max-width="50"
                        ></v-img>
                        </div>
                      </template>
                      <template v-slot:item.codAmount="{ item }">
                        {{ format_price(item.codAmount) }}
                      </template>
                      <template v-slot:item.showDetail="{ item }">
                        <span href="#" @click="get_detail_report(1,item)"><v-icon color="primary">mdi-tooltip-text</v-icon></span>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="pt-0 px-0" cols="12" v-else-if="this.tab == 2">
                <v-row class="mt-0 px-0 mx-0 pb-1" style="background: #fff;">
                  <v-col cols="12" md="3" lg="3" class="pb-0">
                  <!-- ค้นหา 2 -->
                    <v-menu
                      ref="menu_search_sent_date_from"
                      v-model="menu_search_sent_date_from"
                      :close-on-content-click="false"
                      :return-value.sync="sent_date_from"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="computed_search_sent_date_from"
                          @change="value => computed_search_sent_date_from = value"
                          autocomplete="off"
                          label="เริ่ม"
                          outlined
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          hide-details
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="sent_date_from" @input="menu_search_sent_date_from = false, $refs.menu_search_sent_date_from.save(sent_date_from)" no-title :allowed-dates="allowedDates" class="my-0">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" >
                    <v-menu
                      ref="menu_search_sent_date_to"
                      v-model="menu_search_sent_date_to"
                      :close-on-content-click="false"
                      :return-value.sync="sent_date_to"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="computed_search_sent_date_to"
                          @change="value => computed_search_sent_date_to = value"
                          autocomplete="off"
                          label="สิ้นสุด"
                          outlined
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          hide-details
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="sent_date_to" @input="menu_search_sent_date_to = false, $refs.menu_search_sent_date_to.save(sent_date_to)" no-title :allowed-dates="allowedDates" class="my-0">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" class="pb-0" align="center">
                    <v-btn @click="get_data_tab2()" color="primary" class="mr-3">{{use_language.search}}</v-btn>
                    <v-btn @click="SearchingClearData()" outlined color="primary" class="mr-3">{{use_language.reset}}</v-btn>
                  </v-col>
                </v-row>
                <v-row class="mx-0 px-0">
                  <v-col cols="12">
                    <v-data-table :headers="headers2" :items="showdataTable2" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
                      <template v-slot:item.logoPicSmall="{ item }">
                        <div align="center">
                          <v-img style="margin:5px;"
                            :src="item.logoPicSmall"
                            max-width="50"
                        ></v-img>
                        </div>
                      </template>
                      <template v-slot:item.countTotal="{ item }">
                        {{ format_price(item.countTotal) }}
                      </template>
                      <template v-slot:item.showDetail="{ item }">
                        <span href="#" @click="get_detail_report(2,item)"><v-icon color="primary">mdi-tooltip-text</v-icon></span>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-dialog v-model="dialog_detail" scrollable persistent width="auto" >
          <v-card  class="my-0">
            <v-card-title>
              <span class="headline">{{use_language.list_order}}</span>
              <vue-excel-xlsx
                  :data="tab_data_detail_all_status"
                  :columns="excel_order_header"
                   v-bind:filename='use_language.order_data_report'
                  sheetname="shee1"
                  >
                <v-icon color="success" class="ml-5">mdi-file-excel</v-icon>
              </vue-excel-xlsx>
              <v-spacer></v-spacer>
              <v-icon color="danger" @click="dialog_detail = false">mdi-close-circle-outline</v-icon>
            </v-card-title>
            <v-divider/>
            <v-card-text>
              <v-row >
                <v-col cols="12">
                  <v-tabs v-model="detail_tabSelect">
                    <v-tab @click="detail_changeTab(1)">{{use_language.successful_delivery}} {{ this.tab_data_detail_success.length }} {{use_language.item}}</v-tab>
                    <v-tab @click="detail_changeTab(2)">{{use_language.during_delivery}} {{ this.tab_data_detail_sending.length }}  {{use_language.item}}</v-tab>
                    <v-tab @click="detail_changeTab(3)">{{use_language.bounce}} {{ this.tab_data_detail_return.length }}  {{use_language.item}}</v-tab>
                  </v-tabs>
                </v-col>
              </v-row>
              <v-row v-show="this.detail_tab == 1" style="height: 600px;">
                <v-col cols="12" >
                  <v-data-table :headers="headers_detail" :items="tab_data_detail_success" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
                    <template v-slot:item.orderMasterID="{ item }">
                      <a href="#" class="text-info" @click="detail_page(item.token)">PA{{ FormatOrder(item.orderMasterID) }}</a>
                    </template>
                    <template v-slot:item.fullBankName="{ item }">
                      <div v-if="item.codreportID != null">
                        COD Wallet
                      </div>
                      <div v-else>
                        {{ item.fullBankName }}
                      </div>
                    </template>
                    <template v-slot:item.codAmount="{ item }">
                      {{ format_price(item.codAmount) }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row v-show="this.detail_tab == 2" style="height: 600px;">
                <v-col cols="12" >
                  <v-data-table :headers="headers_detail" :items="tab_data_detail_sending" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
                    <template v-slot:item.orderMasterID="{ item }">
                      <a href="#" class="text-info" @click="detail_page(item.token)">PA{{ FormatOrder(item.orderMasterID) }}</a>
                    </template>
                    <template v-slot:item.fullBankName="{ item }">
                      <div v-if="item.codreportID != null">
                        COD Wallet
                      </div>
                      <div v-else>
                        {{ item.fullBankName }}
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row v-show="this.detail_tab == 3" style="height: 600px;">
                <v-col cols="12" >
                  <v-data-table :headers="headers_detail" :items="tab_data_detail_return" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
                    <template v-slot:item.orderMasterID="{ item }">
                      <a href="#" class="text-info" @click="detail_page(item.token)">PA{{ FormatOrder(item.orderMasterID) }}</a>
                    </template>
                    <template v-slot:item.fullBankName="{ item }">
                      <div v-if="item.codreportID != null">
                        COD Wallet
                      </div>
                      <div v-else>
                        {{ item.fullBankName }}
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider/>
            <v-card-actions >
              <v-row align="center" justify="center" class="mx-0">
                <v-col cols="12" align="center" justify="center">{{use_language.total_cod_all}}<span class="text-success">{{ this.dialog_detail_Sum_COD }}</span> {{use_language.bath}}</v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Loading from '@/website/components/Loading'
    import axios from 'axios'
    import { reportService_dotnet } from '@/website/global'
    import { format_number, format_order_id, format_price,get_languages } from '@/website/global_function'
    import { token_api, header_token } from '@/website/backend/token'
   

   
   export default {
        components: {
            Loading
        },

        data: () => ({
            page_loading: true,
            loading: false,
            use_language: null,
            set_language: null,

            isSearching: null,
            menu_search_tranferred_date_from: false,
            transferred_date_from: null,
            menu_search_tranferred_date_to: false,
            transferred_date_to: null,
            menu_search_sent_date_from: false,
            sent_date_from: null,
            menu_search_sent_date_to: false,
            sent_date_to: null,

            tabSelect: 0,
            tab: 1,
            showdataTable: [],
            showdataTable1: [],
            showdataTable2: [],
            showdataTable3: [],
            tab_data_detail_success: [],
            tab_data_detail_sending: [],
            tab_data_detail_return: [],
            tab_data_detail_all_status: [],

            detail_tabSelect:0,
            detail_tab:1,

            dialog_detail: false,
            dialog_detail_Sum_COD: 0,

            headers1: [
              // { text: 'ลำดับ', align: 'center', sortable: false, value: 'num' },
              { text: 'วันที่เงินเข้า', align: 'left', sortable: false, value: 'realTransferDatetime_show' },
              { text: 'ขนส่ง', align: 'center', value: 'logoPicSmall', sortable: false },
              { text: 'ธนาคาร', align: 'left', value: 'bankNameTH', sortable: false },
              { text: 'ชื่อบัญชี', align: 'left', value: 'accountName', sortable: false },
              { text: 'หมายเลขบัญชี', align: 'left', value: 'accountName', sortable: false },
              { text: 'ยอดรวม(COD)', align: 'right', value: 'codAmount', sortable: false },
              { text: 'จำนวนพัสดุ ', align: 'center', value: 'sumOrder', sortable: false },
              { text: 'รายละเอียด', align: 'center', value: 'showDetail', sortable: false },
            ],

            headers2: [
              // { text: 'ลำดับ', align: 'center', sortable: false, value: 'num' },
              { text: 'วันที่ส่ง', align: 'left', value: 'sendToExpressDatetime_show', sortable: false },
              { text: 'ขนส่ง', align: 'center', value: 'logoPicSmall', sortable: false },
              { text: 'ระหว่างจัดส่ง', align: 'center', value: 'countOnTheWay', sortable: false },
              { text: 'ตีกลับ', align: 'center', value: 'countReturn', sortable: false },
              { text: 'ส่งสำเร็จ', align: 'center', value: 'countSuccess', sortable: false },
              { text: '%สำเร็จ', align: 'right', value: 'percentSent', sortable: false },
              { text: 'COD ทั้งหมด', align: 'right', value: 'countTotal', sortable: false },
              { text: 'COD ที่โอนแล้ว', align: 'right', value: 'sumTransfered', sortable: false },
              { text: 'รายละเอียด', align: 'center', value: 'showDetail', sortable: false },
            ],

            headers_detail: [],
            headers_detail_success: [
              { text: 'OrderID', align: 'center', sortable: false, value: 'orderMasterID' },
              { text: 'สร้างวันที่', align: 'left', value: 'createdDatetime', sortable: false },
              { text: 'วันที่ส่ง', align: 'left', value: 'sendToExpressDatetime', sortable: false },
              { text: 'TrackingNo', align: 'center', value: 'trackingNo', sortable: false },
              { text: 'ชื่อผู้รับ', align: 'left', value: 'receiverName', sortable: false },
              { text: 'จัดส่งสำเร็จ', align: 'left', value: 'parcelSuccessDateTime', sortable: false },
              { text: 'COD', align: 'right', value: 'codAmount', sortable: false },
              { text: 'ธนาคาร', align: 'left', value: 'fullBankName', sortable: false },
              { text: 'วันที่เงินเข้า', align: 'left', value: 'realTransferDatetime', sortable: false },
              { text: 'สร้างโดย', align: 'center', value: 'createdByStaffShopName', sortable: false },
              { text: 'พนักงานขาย', align: 'center', value: 'salemanName', sortable: false },
            ],
            headers_detail_sending: [
              { text: 'OrderID', align: 'center', sortable: false, value: 'orderMasterID' },
              { text: 'สร้างวันที่', align: 'left', value: 'createdDatetime', sortable: false },
              { text: 'วันที่ส่ง', align: 'left', value: 'sendToExpressDatetime', sortable: false },
              { text: 'TrackingNo', align: 'center', value: 'trackingNo', sortable: false },
              { text: 'ชื่อผู้รับ', align: 'left', value: 'receiverName', sortable: false },
              { text: 'COD', align: 'right', value: 'codAmount', sortable: false },
              { text: 'ธนาคาร', align: 'left', value: 'fullBankName', sortable: false },
              { text: 'สร้างโดย', align: 'center', value: 'createdByStaffShopName', sortable: false },
              { text: 'พนักงานขาย', align: 'center', value: 'salemanName', sortable: false },
            ],
            headers_detail_return: [
              { text: 'OrderID', align: 'center', sortable: false, value: 'orderMasterID' },
              { text: 'สร้างวันที่', align: 'left', value: 'createdDatetime', sortable: false },
              { text: 'วันที่ส่ง', align: 'left', value: 'sendToExpressDatetime', sortable: false },
              { text: 'TrackingNo', align: 'center', value: 'trackingNo', sortable: false },
              { text: 'ชื่อผู้รับ', align: 'left', value: 'receiverName', sortable: false },
              { text: 'COD', align: 'right', value: 'codAmount', sortable: false },
              { text: 'วันที่ตีกลับ', align: 'left', value: 'returnedDateTime', sortable: false },
              { text: 'สร้างโดย', align: 'center', value: 'createdByStaffShopName', sortable: false },
              { text: 'พนักงานขาย', align: 'center', value: 'salemanName', sortable: false },
            ],


            excel_order_header: [
              { label: 'เลขออเดอร์', field: 'orderMasterID_show'},
              { label: 'สร้างวันที่',  field: 'createdDatetime' },
              { label: 'วันที่ส่ง',  field:'sendToExpressDatetime' },
              { label: 'สถานะการส่งพัสดุ',  field:'parcelStatusName' },
              { label: 'TrackingNo',  field: 'trackingNo' },
              { label: 'ชื่อผู้รับ',  field: 'receiverName' },
              { label: 'จัดส่งสำเร็จ', field: 'parcelSuccessDateTime' },
              { label: 'COD', align: 'right', field: 'codAmount' },
              { label: 'ธนาคาร', field: 'fullBankName' },
              { label: 'วันที่เงินเข้า', field: 'realTransferDatetime' },
              { label: 'สร้างโดย', field: 'createdByStaffShopName' },
              { label: 'พนักงานขาย', field: 'salemanName' },
              { label: 'วันที่ตีกลับ', field: 'returnedDateTime' },

            ],
        }),

        computed: {
          computed_search_sent_date_from() {
            return this.formatDates(this.sent_date_from)
          },

          computed_search_sent_date_to() {
            return this.formatDates(this.sent_date_to)
          },

          computed_search_tranferred_date_from() {
            return this.formatDates(this.transferred_date_from)
          },

          computed_search_tranferred_date_to() {
            return this.formatDates(this.transferred_date_to)
          },

        },

        async created() {

           //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));



            this.shop_id = localStorage.getItem("shop_id")
            this.staff_id = localStorage.getItem("staff_id")
            await this.changeTab(this.tab)
            await this.get_data_tab1()
            this.page_loading = false
            await this.get_data_tab2()
        },

        methods: {
          format_number,
          format_order_id,
          format_price,
          get_languages,
          allowedDates: val => val <= new Date().toISOString().substr(0, 10),

          formatDates (date) {
            if (date == null){
              return null
            } else {
              const [year, month, day] = date.split('-')
              return `${day}-${month}-${year}`
            }
          },

          detail_page (token) {
            window.open('order-detail?token='+token, '_blank');
          },

          FormatOrder(orderid){
            if(orderid==null)
              return ""
            var returnString=""
            var len = orderid.toString().length
            if(len<6){

               for (var i = len; i <= 5; i++) {
                 returnString=returnString+"0"
               }
            }
            returnString=returnString+orderid.toString()
            return returnString
          },

          changeTab (tab) {
            if (tab == 1){
              this.tabSelect = 0
            }else if(tab == 2) {
              this.tabSelect = 1
            }
            this.tab = tab
          },

          async get_data_tab1(){
            this.isSearching = false
            this.loading = true
            let response = await axios.post(reportService_dotnet+'Report/get-cod-report-group-by-realtransferdatetime', {
              "shopID": localStorage.getItem('shop_id'),
              "startDate": this.transferred_date_from,
              "endDate": this.transferred_date_to
            }, {headers: header_token})
              this.loading = false
              this.showdataTable1 = []
              this.showdataTable1 = response.data.data_list
              this.transferred_date_from = response.data.start_date
              this.transferred_date_to = response.data.end_date
          },

          async get_data_tab2(){
            this.isSearching = false
            this.loading = true
            let response = await axios.post(reportService_dotnet+'Report/get-cod-report-group-by-sendtoexpressdatetime', {
              "shopID": localStorage.getItem('shop_id'),
              "startDate": this.sent_date_from,
              "endDate": this.sent_date_to
            }, {headers: header_token})
              this.loading = false
              this.showdataTable2 = []
              this.showdataTable2 = response.data.data_list
              this.sent_date_from = response.data.start_date
              this.sent_date_to = response.data.end_date
          },

          async get_detail_report(type, item){
            this.loading = true
            this.changeTab(this.tab)
            this.detail_changeTab(1)
            var date_tmp ;
            if(type == 1){
              date_tmp = item.realTransferDatetime;
              this.dialog_detail_Sum_COD = item.codAmount
            }else {
              date_tmp = item.sendToExpressDatetime;
              this.dialog_detail_Sum_COD = item.countTotal
            }

            let response = await axios.post(reportService_dotnet+'Report/get-detail-cod-report-group-by', {
              "shopID": localStorage.getItem('shop_id'),
              "type": type,
              "date_time": date_tmp
            }, {headers: header_token})
              this.loading = false
              this.tab_data_detail_succes = []
              this.tab_data_detail_sending = []
              this.tab_data_detail_return = []
              this.tab_data_detail_all_status = []
              this.tab_data_detail_success = response.data.data_success
              this.tab_data_detail_sending = response.data.data_onTheWay
              this.tab_data_detail_return = response.data.data_return
              this.tab_data_detail_all_status = response.data.data_all
              this.dialog_detail = true
          },

          detail_changeTab (detail_tab) {
            if (detail_tab == 1){
              this.detail_tabSelect = 0
              this.headers_detail = this.headers_detail_success
            }else if(detail_tab == 2) {
              this.detail_tabSelect = 1
              this.headers_detail = this.headers_detail_sending
            }else if(detail_tab == 3) {
              this.detail_tabSelect = 2
              this.headers_detail = this.headers_detail_return
            }
            this.detail_tab = detail_tab
          },

          SearchingClearData () {
            var nowDate = new Date()
            this.transferred_date_to = nowDate.toISOString().substr(0, 10)
            nowDate.setDate(nowDate.getDate()-7)
            this.transferred_date_from = nowDate.toISOString().substr(0, 10)

            this.sent_date_to = nowDate.toISOString().substr(0, 10)
            nowDate.setDate(nowDate.getDate()-7)
            this.sent_date_from = nowDate.toISOString().substr(0, 10)

          },

        }
    }
</script>
